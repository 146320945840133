import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import {MainWrap} from '../pages/web.js'
import {BlogArticleWrap} from '../pages/index.js'
import Articles from "../components/articles"
import {TagStyle} from '../templates/blog-post.js'
import { val }  from '../components/cssvalue'
// STYLE
export const TagStyleBlog = styled(TagStyle)`
  background: ${val.BG};
  margin-top: 50px;
  padding: 20px 20px 40px;
  .title {
    text-align: center;
  }
  .tag {
    .tagLink {
      background:  ${val.WHITE};
    }
  }
`;
// Components
const TagPage = ({ pageContext, data, location}) => {
  const { tag } = pageContext
  const posts = data.article.nodes
  const group = data.tagData.group
  const title = `タグ「${tag}」の記事一覧 | ブログ |   ${data.site.siteMetadata?.title}`
  return (
    <Layout location={location}>
      <Seo
        title={title}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>タグ「{tag}」の記事一覧</h1>
          <BlogArticleWrap>
            {posts.map((node) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <Articles
                  post={node}
                  title={title}
                  titileTag="h2"
                 />
              )
          })}
          </BlogArticleWrap>
          <TagStyleBlog>
            <h2 class="title">タグ一覧</h2>
            <div className="tag">
            {group.map(tag => (
              <Link to={`/tags/${tag.fieldValue}/`} className="tagLink" key={tag.fieldValue}>
                <i>{tag.fieldValue} ({tag.totalCount})</i>
              </Link>
            ))}
            </div>
          </TagStyleBlog>
          <div style={{textAlign: 'center', marginTop: '50px'}}>
            <Link to="/blog/" className="btn btn-arrow">
              ブログ・お知らせ 記事一覧
            </Link>
          </div>
        </div>
      </MainWrap>
    </Layout>
  )
}
export default TagPage

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    article: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        excerpt(pruneLength: 120)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY年MM月DD日")
          title
          description
          tags
        }
      }
    }
    tagData: allMarkdownRemark(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }

  }
`